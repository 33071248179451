import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col, Button } from "react-bootstrap";
import { SafeHtmlParser } from "./safe-html-parser";
import ReviewCount from "./ReviewCount";

const Layout02 = ({ heading, body, image, button }) => {
	return (
		<section className="layout02 py-5 py-sm-8">
			<Container>
				<Row className="align-items-center">
					<Col
						md={6}
						className="d-flex justify-content-center position-relative"
					>
						{image?.localFile && (
							<GatsbyImage
								image={image?.localFile.childImageSharp.gatsbyImageData}
								alt={image.altText || "Image"}
								className="layout02__image img-fluid w-100 w-sm-75 mb-5"
							/>
						)}
						<div className="position-absolute bottom-0 end-0 me-2 me-sm-7 bg-white border rounded-3 border-card-yellow p-3">
							<h6>Rated Excellent</h6>
							<div className="d-flex align-items-center">
								{Array.from({ length: 5 }, () => (
									<StaticImage
										src={"../images/star.svg"}
										alt="Your Office People"
										layout="fixed"
										width={24}
									/>
								))}
							</div>
							<div className="d-flex align-items-center mt-3">
								<span className="small-text montserrat me-4">
									Based on <ReviewCount /> reviews
								</span>
								<div className="d-flex align-items-center">
									<StaticImage
										src={"../images/t-shape.svg"}
										alt="Your Office People"
										layout="fixed"
									/>
									<span className="small-text montserrat ms-1">Trustpilot</span>
								</div>
							</div>
						</div>
					</Col>
					<Col md={6} className="text-center text-md-start mb-4 mb-md-0">
						<h2 className="fs-1 mb-3">{heading}</h2>
						<SafeHtmlParser htmlContent={body} />
						{button && (
							<Button
								href={button.url}
								target={button.target}
								variant="secondary"
								className="mt-3 px-4 py-2"
							>
								{button.title}
							</Button>
						)}
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Layout02;
