import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const ExpertConsultation = ({ heading, body, teamMember }) => {
	return (
		<section className="expert-consultation py-5 bg-light">
			<Container>
				<Row className="mb-4 text-center">
					<Col>
						<h2 className="display-4">{heading}</h2>
						<p className="lead">{body}</p>
					</Col>
				</Row>
				<Row className="justify-content-center">
					{teamMember.map((member, index) => (
						<Col
							md={6}
							lg={4}
							key={index}
							className="mb-4 d-flex justify-content-center"
						>
							<Card className="text-center expert-member-card shadow-sm">
								{member.teamMemberFields?.featuredImage?.gatsbyImage && (
									<Card.Img
										variant="top"
										src={
											member.teamMemberFields.featuredImage.gatsbyImage.images
												.fallback.src
										}
										alt={member.teamMemberFields.name || "Expert"}
									/>
								)}
								<Card.Body>
									<Card.Title>{member.teamMemberFields.name}</Card.Title>
									<Card.Subtitle className="text-muted mb-2">
										{member.teamMemberFields.titlerole}
									</Card.Subtitle>
									<Card.Text>{member.teamMemberFields.body}</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
			</Container>
		</section>
	);
};

export default ExpertConsultation;
