import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { SafeHtmlParser } from "./safe-html-parser";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Card } from "react-bootstrap";

const BlogCatalogue = ({
	heading,
	smallHeading,
	body,
	button,
	backgroundColour,
	items,
}) => {
	const [numOfItemsToShow, setNumberOfItems] = useState(6);
	const handleLoadMore = () => {
		setNumberOfItems((prev) => prev + 6);
	};
	return (
		<section
			className="blog-catalogue py-5"
			style={{ backgroundColor: backgroundColour || "white" }}
		>
			<Container>
				<Row className="mb-4 text-center justify-content-center">
					<Col lg={9}>
						{smallHeading && <h4 className="text-green">{smallHeading}</h4>}
						<h3>{heading}</h3>
						{body && <SafeHtmlParser htmlContent={body} />}
					</Col>
				</Row>
				<Row className="justify-content-start mt-6">
					{items.nodes.map((blogItem, index) => (
						<Col
							xs={12}
							md={6}
							xl={4}
							key={index}
							className="mb-4 d-flex justify-content-center"
						>
							<Link
								to={`/blogs${blogItem.uri}`}
								className="d-flex align-items-center text-decoration-none montserrat-semibold fs-6 green-link mb-2 d-flex justify-content-center"
							>
								<Card className="text-start location-card shadow-sm">
									<Card.Body className="p-0">
										<GatsbyImage
											variant="top"
											image={
												blogItem.blogFields?.featuredImage?.localFile
													.childImageSharp.gatsbyImageData
											}
											alt={blogItem.title}
											style={{
												height: "300px",
												borderTopLeftRadius: "12px",
												borderTopRightRadius: "12px",
											}}
										/>
										<div className="p-4">
											<Card.Title className="mb-1" as="h5">
												{blogItem.title}
											</Card.Title>
											{blogItem.excerpt && (
												<div className="small-text">
													<SafeHtmlParser
														htmlContent={blogItem.excerpt.substr(0, 120)}
													/>
												</div>
											)}
											<Button
												className="px-0 d-flex align-items-center text-decoration-none py-0"
												variant="link"
											>
												Read More
												<StaticImage
													src={"../images/arrow-right.svg"}
													alt="Your Office People"
													layout="fixed"
													width={20}
													className="ms-2"
												/>
											</Button>
										</div>
									</Card.Body>
								</Card>
							</Link>
						</Col>
					))}
				</Row>
				{items && numOfItemsToShow < items.length && (
					<Row className="mt-5 text-center">
						<Col>
							<Button
								onClick={handleLoadMore}
								variant="transparent"
								className="px-4 py-2 w-100 w-sm-auto"
							>
								{button.title ?? "Load more"}
							</Button>
						</Col>
					</Row>
				)}
			</Container>
		</section>
	);
};

export default BlogCatalogue;
