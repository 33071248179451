import React from "react";
import { BgImage } from "gbimage-bridge";
import { Container, Row, Col, Button } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazyload";
import ReactPlayer from "react-player";

const VideoIntro = ({
	body,
	heading,
	imageOrVideo,
	button,
	backgroundImage,
	backgroundVideo,
}) => {
	return (
		<section className=" bg-green-bg py-5">
			<Container className="position-relative">
				<Row className="align-items-center">
					<Col md={12} className="position-relative">
						{imageOrVideo === "Image" && backgroundImage?.gatsbyImage && (
							<GatsbyImage
								style={{ borderRadius: "12px" }}
								image={backgroundImage.gatsbyImage}
								className="video-intro__background-image w-100"
							/>
						)}
						{imageOrVideo === "Video" && backgroundVideo?.mediaItemUrl && (
							<div
								style={{ borderRadius: "12px" }}
								className="video-hero-container position-relative overflow-hidden"
							>
								<div className="video-wrapper">
									<ReactPlayer
										playsinline
										controls
										width="100%"
										height="100%"
										playing={true}
										muted={true}
										url={backgroundVideo.mediaItemUrl}
										className="video-player"
									/>
								</div>
							</div>
						)}
					</Col>
					<Col md={12} className=" pt-4">
						<h3 className="mb-3">{heading}</h3>

						<p>{body}</p>
						{button && (
							<Button
								href={button.url}
								target={button.target}
								variant="secondary"
								className=" w-auto px-4"
							>
								{button.title}
							</Button>
						)}
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default VideoIntro;
