import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

// Import components
import TrustedBy from "../components/TrustedBy";
import VideoIntro from "../components/VideoIntro";
import HeaderLayout01 from "../components/HeaderLayout01";
import Layout01 from "../components/Layout01";
import Layout02 from "../components/Layout02";
import FeatureList01 from "../components/FeatureList01";
import OurTeam01 from "../components/OurTeam01";
import OurTeam02 from "../components/OurTeam02";
import ProcessSteps from "../components/ProcessSteps";
import Cta from "../components/Cta";
import CustomerReviews01 from "../components/CustomerReviews01";
import CustomerReviews02 from "../components/CustomerReviews02";
import Areas from "../components/Areas";
import BookingForm from "../components/BookingForm";
import SocialMediaPosts from "../components/SocialMediaPosts";
import BlogCatalogue from "../components/BlogCatalogue";
import GetInTouch from "../components/GetInTouch";
import ExpertConsultation from "../components/ExpertConsultation";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const PageTemplate = ({ data: { wpPage, allWpPost, site } }) => {
	const siteUrl = site?.siteMetadata?.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: `${wpPage.seoFields.metaTitle}`,
				item: {
					url: `${siteUrl}/${wpPage.slug}`,
					id: `${siteUrl}/${wpPage.slug}`,
				},
			},
		],
	};

	const renderComponent = (component, index) => {
		switch (component.fieldGroupName) {
			case "Page_Pagefields_Components_VideoIntro":
				return (
					<VideoIntro
						key={index}
						body={component.body}
						heading={component.heading}
						imageOrVideo={component.imageOrVideo}
						button={component.button}
						backgroundImage={component.backgroundImage}
						backgroundVideo={component.backgroundVideo}
					/>
				);

			case "Page_Pagefields_Components_TrustedBy":
				return (
					<TrustedBy
						key={index}
						heading={component.heading}
						logos={component.logos}
					/>
				);
			case "Page_Pagefields_Components_HeaderLayout01":
				return (
					<HeaderLayout01
						key={index}
						heading={component.heading}
						body={component.body}
						imageOrVideo={component.imageOrVideo}
						backgroundImage={component.backgroundImage}
						button={component.button}
						backgroundVideo={component.backgroundVideo}
					/>
				);
			case "Page_Pagefields_Components_Layout01":
				return (
					<Layout01
						key={index}
						heading={component.heading}
						smallHeading={component.smallHeading}
						body={component.body}
						image={component.image}
						button={component.button}
					/>
				);
			case "Page_Pagefields_Components_Layout02":
				return (
					<Layout02
						key={index}
						heading={component.heading}
						body={component.body}
						image={component.image}
					/>
				);
			case "Page_Pagefields_Components_FeatureList01":
				return (
					<FeatureList01
						key={index}
						heading={component.heading}
						smallHeading={component.smallHeading}
						body={component.body}
						propertyIds={component.propertyIds}
						button={component.button}
					/>
				);
			case "Page_Pagefields_Components_OurTeam01":
				return (
					<OurTeam01
						key={index}
						bannerHeading={component.bannerHeading}
						bannerBody={component.bannerBody}
						heading={component.heading}
						body={component.body}
						teamMember={component.teamMember}
					/>
				);
			case "Page_Pagefields_Components_OurTeam02":
				return (
					<OurTeam02
						key={index}
						heading={component.heading}
						body={component.body}
						teamMembers={component.teamMembers}
					/>
				);
			case "Page_Pagefields_Components_ProcessSteps":
				return (
					<ProcessSteps
						key={index}
						heading={component.heading}
						body={component.body}
						button={component.button}
						step1={component.step1}
						step2={component.step2}
						step3={component.step3}
					/>
				);
			case "Page_Pagefields_Components_Cta":
				return (
					<Cta
						key={index}
						heading={component.heading}
						body={component.body}
						button={component.button}
						backgroundImage={component.backgroundImage}
					/>
				);
			case "Page_Pagefields_Components_CustomerReviews01":
				return (
					<CustomerReviews01
						key={index}
						heading={component.heading}
						body={component.body}
						reviews={component.reviews}
						button={component.button}
						backgroundColour={component.backgroundColour}
					/>
				);
			case "Page_Pagefields_Components_CustomerReviews02":
				return (
					<CustomerReviews02
						key={index}
						heading={component.heading}
						body={component.body}
						reviews={component.reviews}
						button={component.button}
					/>
				);
			case "Page_Pagefields_Components_Areas":
				return (
					<Areas
						key={index}
						heading={component.heading}
						body={component.body}
						popularLocations={component.popularLocations}
						button={component.button}
						button2={component.button2}
					/>
				);
			case "Page_Pagefields_Components_BookingForm":
				return (
					<BookingForm
						key={index}
						heading={component.heading}
						body={component.body}
						backgroundImage={component.backgroundImage}
					/>
				);
			case "Page_Pagefields_Components_SocialMediaPosts":
				return (
					<SocialMediaPosts
						key={index}
						heading={component.heading}
						secondHeading={component.secondHeading}
						body={component.body}
						socialLinks={component.socialLinks}
						socialPosts={component.socialPosts}
						backgroundColour={component.backgroundColour}
					/>
				);
			case "Page_Pagefields_Components_BlogCatalogue":
				return (
					<BlogCatalogue
						key={index}
						heading={component.heading}
						smallHeading={component.smallHeading}
						body={component.body}
						button={component.button}
						backgroundColour={component.backgroundColour}
						items={allWpPost}
					/>
				);
			case "Page_Pagefields_Components_GetInTouch":
				return (
					<GetInTouch
						key={index}
						heading={component.heading}
						body={component.body}
						contacts={component.contacts}
						teamMembers={component.teamMembers}
						backgroundColour={component.backgroundColour}
					/>
				);
			case "Page_Pagefields_Components_ExpertConsultation":
				return (
					<ExpertConsultation
						key={index}
						heading={component.heading}
						body={component.body}
						teamMember={component.teamMember}
					/>
				);
			default:
				return <div key={index}></div>;
		}
	};

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={wpPage.seoFields?.metaTitle}
				description={wpPage.seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/${wpPage.slug}`,
					title: `${wpPage.seoFields?.opengraphTitle}`,
					description: `${wpPage.seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${wpPage.seoFields?.image?.localFile.publicURL}`,
							width: `${wpPage.seoFields?.image?.localFile.childImageSharp.original.width}`,
							height: `${wpPage.seoFields?.image?.localFile.childImageSharp.original.height}`,
							alt: `${wpPage.seoFields?.image?.altText}`,
						},
					],
				}}
			/>
			<section className="position-relative">
				{wpPage.pageFields.components.map((component, index) =>
					renderComponent(component, index)
				)}
			</section>
		</Layout>
	);
};

export default PageTemplate;

export const pageQuery = graphql`
	query PageById($id: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		allWpPost(filter: { slug: { ne: "website-images" } }) {
			nodes {
				blogFields {
					featuredImage {
						altText
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: WEBP
									quality: 80
									transformOptions: { cropFocus: CENTER, fit: COVER }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				title
				uri
				excerpt
			}
		}
		wpPage(id: { eq: $id }) {
			slug
			seoFields {
				metaTitle
				metaDescription
				opengraphDescription
				opengraphTitle
				productSchema
				image {
					altText
					localFile {
						childImageSharp {
							original {
								height
								width
							}
						}
						publicURL
					}
				}
			}
			title
			pageFields {
				components {
					... on WpPage_Pagefields_Components_TrustedBy {
						fieldGroupName
						heading
						logos {
							fieldGroupName

							image {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_HeaderLayout01 {
						body
						fieldGroupName
						heading
						imageOrVideo
						backgroundImage {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						button {
							target
							title
							url
						}
						backgroundVideo {
							altText
							sourceUrl
						}
					}
					... on WpPage_Pagefields_Components_Layout01 {
						body
						fieldGroupName
						smallHeading
						heading
						button {
							target
							title
							url
						}
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					... on WpPage_Pagefields_Components_Layout02 {
						body
						fieldGroupName
						heading
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					... on WpPage_Pagefields_Components_FeatureList01 {
						body
						fieldGroupName
						smallHeading
						heading
						propertyIds {
							id
							fieldGroupName
						}
						button {
							target
							title
							url
						}
					}
					... on WpPage_Pagefields_Components_OurTeam01 {
						bannerBody
						bannerHeading
						bannerButton {
							target
							title
							url
						}
						body
						heading
						teamMember {
							... on WpTeamMember {
								id
								title
								teamMemberFields {
									body
									email
									fieldGroupName
									name
									titlerole
									phoneNumbers {
										number
										fieldGroupName
									}
									images {
										altText
										gatsbyImage(
											width: 1920
											quality: 75
											formats: [WEBP]
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
									featuredImage {
										altText
										gatsbyImage(
											width: 1920
											quality: 100
											formats: [WEBP]
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						fieldGroupName
					}
					... on WpPage_Pagefields_Components_OurTeam02 {
						body
						fieldGroupName
						heading
						teamMembers {
							... on WpTeamMember {
								id
								teamMemberFields {
									name
									body
									featuredImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: WEBP
													quality: 80
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
									email
									images {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: WEBP
													quality: 80
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
									phoneNumbers {
										number
									}
									titlerole
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_ProcessSteps {
						body
						fieldGroupName
						heading
						button {
							target
							title
							url
						}
						step1 {
							heading
							body
							fieldGroupName
							icon {
								altText
								sourceUrl
							}
						}
						step3 {
							body
							heading
							fieldGroupName
							icon {
								altText
								sourceUrl
							}
						}
						step2 {
							body
							fieldGroupName
							heading
							icon {
								altText
								sourceUrl
							}
						}
					}
					... on WpPage_Pagefields_Components_Cta {
						body
						fieldGroupName
						heading
						button {
							target
							title
							url
						}
						backgroundImage {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					... on WpPage_Pagefields_Components_CustomerReviews01 {
						backgroundColour
						fieldGroupName
						body
						reviews {
							... on WpReview {
								id
								reviewFields {
									body
									name
									fieldGroupName
								}
								title
							}
						}
						heading
						button {
							target
							title
							url
						}
					}
					... on WpPage_Pagefields_Components_CustomerReviews02 {
						body
						fieldGroupName
						heading
						button {
							target
							title
							url
						}
						reviews {
							... on WpReview {
								id
								title
								reviewFields {
									body
									fieldGroupName
									name
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_Areas {
						body
						fieldGroupName
						popularLocations {
							... on WpPopularLocation {
								id
								popularLocationsFields {
									url
									featuredImage {
										altText
										sourceUrl
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: WEBP
													quality: 80
													transformOptions: { cropFocus: CENTER, fit: COVER }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
								title
								slug
							}
						}
						heading
						button {
							target
							title
							url
						}
						button2 {
							target
							title
							url
						}
					}
					... on WpPage_Pagefields_Components_BookingForm {
						body
						fieldGroupName
						heading
						backgroundImage {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 70
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_VideoIntro {
						body
						fieldGroupName
						imageOrVideo
						heading
						button {
							target
							title
							url
						}
						backgroundImage {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						backgroundVideo {
							altText
							sourceUrl
							mediaItemUrl
						}
					}
					... on WpPage_Pagefields_Components_SocialMediaPosts {
						backgroundColour
						body
						heading
						secondHeading
						fieldGroupName
						socialLinks {
							url
							fieldGroupName
							icon {
								altText
								sourceUrl
							}
						}
						socialPosts {
							body
							fieldGroupName
							image {
								altText
								gatsbyImage(
									width: 1920
									quality: 75
									formats: [WEBP]
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
					... on WpPage_Pagefields_Components_BlogCatalogue {
						backgroundColour
						fieldGroupName
						body
						smallHeading
						heading
						button {
							title
							url
							target
						}
					}
					... on WpPage_Pagefields_Components_GetInTouch {
						backgroundColour
						body
						heading
						fieldGroupName
						contacts {
							heading
							fieldGroupName
							link {
								target
								title
								url
							}
							icon {
								altText
								sourceUrl
							}
						}
						teamMembers {
							... on WpTeamMember {
								id
								title
								teamMemberFields {
									body
									email
									name
									titlerole
									phoneNumbers {
										number
										fieldGroupName
									}
									fieldGroupName
									featuredImage {
										altText
										gatsbyImage(
											width: 1920
											quality: 100
											formats: [WEBP]
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
									images {
										altText
										gatsbyImage(
											width: 1920
											quality: 75
											formats: [WEBP]
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpPage_Pagefields_Components_ExpertConsultation {
						body
						fieldGroupName
						teamMember {
							... on WpTeamMember {
								id
								title
								teamMemberFields {
									body
									email
									name
									titlerole
									phoneNumbers {
										fieldGroupName
										number
									}
									fieldGroupName
									featuredImage {
										altText
										gatsbyImage(
											width: 1920
											quality: 100
											formats: [WEBP]
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
									images {
										altText
										gatsbyImage(
											width: 1920
											quality: 75
											formats: [WEBP]
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						heading
					}
				}
				fieldGroupName
			}
		}
	}
`;
