import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { SafeHtmlParser } from "./safe-html-parser";
import { StaticImage } from "gatsby-plugin-image";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import Star from "../images/star.svg";
import ReviewCount from "./ReviewCount";

const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 3,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const CustomLeftArrow = ({ onClick }) => {
	return (
		<button
			onClick={onClick}
			className="customer-review-arrow customer-review-arrow-left"
		>
			<div className="pb-2 pt-1 px-3 text-white">
				<FaArrowLeftLong />
			</div>
		</button>
	);
};

// Custom Right Arrow Component
const CustomRightArrow = ({ onClick }) => {
	return (
		<button
			onClick={onClick}
			className="customer-review-arrow customer-review-arrow-right"
		>
			<div className="pb-2 pt-1 px-3 text-white">
				<FaArrowRightLong />
			</div>
		</button>
	);
};

const CustomerReviews02 = ({
	heading,
	body,
	reviews,
	button,
	backgroundColour,
}) => {
	return (
		<section
			className="customer-reviews01 py-5 py-md-8 bg-primary
			"
		>
			<Container>
				<Row className="mb-4 text-center justify-content-center">
					<Col md={9}>
						<h3 className="mb-2 text-white">{heading}</h3>
						{body && (
							<div className="text-white">
								<SafeHtmlParser htmlContent={body} />
							</div>
						)}
						{button && (
							<Button
								href={button.url}
								target={button.target}
								variant="secondary"
								className="px-4 py-2 mt-3 w-100 w-md-auto"
							>
								{button.title}
							</Button>
						)}
					</Col>
				</Row>
				<Row className="mt-5 d-flex">
					<Col
						md={4}
						xl={3}
						className="text-start d-flex justify-content-center pb-7"
					>
						<div
							className="bg-white d-flex align-items-center justify-content-center flex-column text-center card-shadow w-100"
							style={cardWrapper}
						>
							<h6 className="text-primary">Excellent</h6>
							<div className="d-flex align-items-center">
								{Array.from({ length: 5 }, () => (
									<img
										src={Star}
										alt="Your Office People"
										layout="fixed"
										style={{ width: "28px" }}
									/>
								))}
							</div>
							<a
								target="_blank"
								rel="noreferrer"
								href="https://uk.trustpilot.com/review/www.yourofficepeople.co.uk"
							>
								<p className="mt-3">
									Base on{" "}
									<span className="montserrat-bold text-decoration-underline">
										<ReviewCount /> reviews
									</span>
								</p>
							</a>
							<StaticImage
								src={"../images/trustpilot.png"}
								alt="Your Office People"
								layout="fixed"
								width={110}
							/>
						</div>
					</Col>
					<Col md={8} xl={9} className="p-0 text-start">
						<div>
							{reviews && reviews.length > 0 && (
								<Carousel
									swipeable={false}
									draggable={false}
									showDots={false}
									responsive={responsive}
									infinite={true}
									customLeftArrow={<CustomLeftArrow />}
									customRightArrow={<CustomRightArrow />}
								>
									{reviews.map((item, i) => (
										<a
											target="_blank"
											rel="noreferrer"
											href="https://uk.trustpilot.com/review/www.yourofficepeople.co.uk"
										>
											<div className="reviews-item h-100" key={i}>
												<div
													className="card-shadow mx-3 me-sm-3 ms-sm-0 h-100 bg-white"
													style={cardWrapper}
												>
													<div className="d-flex justify-content-between">
														<div className="d-flex align-items-center">
															{Array.from({ length: 5 }, () => (
																<img
																	src={Star}
																	alt="Your Office People"
																	layout="fixed"
																	style={{ width: "20px" }}
																/>
															))}
														</div>
														<span
															className="montserrat-light fw-light"
															style={{ fontSize: "12px" }}
														>
															{item.date}
														</span>
													</div>
													<h6 className="pt-3 pb-2 mb-0">{item.title}</h6>
													<div className="fw-light content-wrapper">
														<p>{item.reviewFields.body}</p>
													</div>

													<hr
														className="bg-schema-grey my-2"
														style={{ width: "60px", height: "1px" }}
													/>
													<span className="fw-bold montserrat-bold">
														{item.reviewFields.name}
													</span>
												</div>
											</div>
										</a>
									))}
								</Carousel>
							)}
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export const cardWrapper = {
	padding: "24px 20px",
	borderRadius: "12px",
};

export default CustomerReviews02;
